import React from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '../components/SEO';
import PrivacyPolicy from '../components/TermsAndConditions/PrivacyPolicy';

const PAGE_TITLE = 'Política de privacidad';
const PAGE_DESCRIPTION =
  'Conoce cómo CurrencyBird protege tus datos personales en transferencias internacionales, asegurando confidencialidad y cumplimiento normativo en Chile.';

const TermsPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <PrivacyPolicy />
    </>
  );
};

export default TermsPage;

export const pageQuery = graphql`
  query TermsPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
