import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '60px',
      paddingTop: '20px',
      paddingBottom: '244px',
      backgroundColor: `#FFF`,
    },
    subpoint: {
      paddingLeft: 40,
    },
    containerSociedad: {
      display: 'flex',
      color: '#004F71',
      paddingLeft: `1%`,
      paddingRight: `1%`,
      marginBottom: '18px',
      backgroundColor: '#2196f30a',
      '& h4': {
        textAlign: `center`,
        fontSize: '13px',
      },
      '& h1': {
        textAlign: `center`,
        fontSize: '15px',
      },
      '& p': {
        textIndent: `1px`,
        fontSize: '12px',
        [theme.breakpoints.down(501)]: {
          fontSize: 13,
        },
      },
      '& h2': {
        fontSize: '18px',
        [theme.breakpoints.down(501)]: {
          fontSize: 16,
        },
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
      [theme.breakpoints.down(501)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
    },
    container: {
      display: 'flex',
      color: '#004F71',
      paddingLeft: `7%`,
      paddingRight: `7%`,
      '& p': {
        textIndent: `25px`,
        [theme.breakpoints.down(501)]: {
          fontSize: 15,
        },
      },
      '& h1': {
        fontSize: '20px',
        [theme.breakpoints.down(501)]: {
          fontSize: 18,
        },
      },
      '& h2': {
        marginBlockStart: '1.33em',
        marginBlockEnd: '1.33em',
        fontSize: '0.875rem',
      },
      '& li': {
        marginBottom: 5,
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
      [theme.breakpoints.down(501)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
    },
    h1: {
      [theme.breakpoints.down(501)]: {
        fontSize: 28,
        lineHeight: `72px`,
        textAlign: `left`,
        fontWeight: 600,
        marginBottom: 30,
      },
    },
    title: {
      textAlign: `center`,
      fontSize: '20px',
      fontWeight: 'bold',
      display: 'block',
      marginBlockStart: '0.83em',
      marginBlockEnd: '0.83em',
    },
  })
);
export default useStyles;
